:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

header, #header {
  text-align: center;
  background: url("background.9040be0a.png") center / cover no-repeat fixed;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
}

h1 {
  color: #fff;
  margin-top: 35vh;
  font-family: Inconsolata, monospace;
  font-size: 400%;
  font-weight: bold;
  position: relative;
}

h2 {
  color: #949494;
  margin-bottom: 40vh;
  font-size: 120%;
  position: relative;
}

h3 {
  color: #2f2f2f;
  text-align: center;
  border-bottom: 1px solid #cbcbcb;
  padding: 15px;
  font-size: 200%;
  position: relative;
}

h4 {
  font-size: 180%;
  position: relative;
}

#mobileheader {
  color: #fff;
  z-index: 1000;
  background-color: #212020;
  width: 100%;
  margin-top: 0;
  padding: 10px;
  font-family: Inconsolata, monospace;
  font-size: 150%;
  display: none;
  position: fixed;
}

#mobileheader a {
  color: #fff;
}

.mobiletitle {
  margin-left: 20px;
}

.light {
  color: #fff;
}

.menu {
  background: #0009;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  z-index: 1000 !important;
}

.menu .inner {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0;
  font-weight: 400;
}

.navBtn {
  float: left;
  text-align: center;
  color: #fff;
  width: 12.65%;
  height: 35px;
  margin: 0;
  padding: 15px 2% 0;
  font-size: 14px;
  text-decoration: none;
  display: block;
}

.menu a:hover {
  color: #fff;
  background: #000c;
}

.active {
  background: #000c;
}

section, .about, .education, .experiences, .skills, .contact {
  z-index: 10;
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
}

.about {
  margin-top: 100vh;
}

.aboutContent {
  text-align: justify;
  margin-top: 40px;
}

.circular {
  border-radius: 150px;
  box-shadow: 0 0 8px #000c;
}

#education {
  box-sizing: border-box;
  background: url("background-education.8b9da8bb.jpg") center / cover no-repeat fixed;
  width: 100%;
}

#experiences {
  color: #323232;
  font-family: Open Sans, sans-serif;
  font-size: 112.5%;
  line-height: 1.6em;
}

#skills {
  background: url("background-skills.0212df13.jpg") center / cover no-repeat fixed;
  width: 100%;
}

#skills .next {
  margin-top: 20px;
}

#contact {
  text-align: center;
}

#contact a {
  color: #000;
  text-align: center;
}

#contact a:hover {
  color: #858585;
}

#contact i {
  margin-top: 20px;
  font-size: 300%;
}

footer, #footer {
  text-align: center;
  color: #cacaca;
  margin-top: 50px;
}

#footer i {
  color: #f4a9a9;
}

#footer a {
  color: #b2b2b2;
  font-size: 80%;
}

@media (width <= 920px) {
  #wrapper {
    background-color: #fff;
  }

  header, #header {
    display: none;
  }

  #about {
    margin-top: 0;
  }

  #mobileheader {
    display: block;
  }

  .skill-card {
    margin-bottom: 10px;
  }
}
/*# sourceMappingURL=index.fb3326de.css.map */
