/* Master css file */
:focus {outline:none;}
::-moz-focus-inner {border:0;}

header, #header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background: url(../images/background.png) no-repeat fixed center;
  background-size: cover;
  text-align: center;
}

h1 {
  position: relative;
  margin-top: 35vh;
  color: white;
  font-family: 'Inconsolata', monospace;
  font-size: 400%;
  font-weight: bold;
}

h2 {
  position: relative;
  color: #949494;
  font-size: 120%;
  margin-bottom: 40vh;
}

h3 {
  position: relative;
  color: #2f2f2f;
  font-size: 200%;
  text-align: center;
  padding: 15px;
  border-bottom: 1px rgb(203, 203, 203) solid;
}

h4 {
  position: relative;
  font-size: 180%;
}

#mobileheader {
  display: none;
  width: 100%;
  margin-top: 0;
  position: fixed;
  background-color: #212020;
  color: white;
  font-family: 'Inconsolata', monospace;
  font-size: 150%;
  padding: 10px;
  z-index: 1000;
}

#mobileheader a {
  color: white;
}

.mobiletitle {
  margin-left: 20px;
}

.light {
  color: white;
}

.menu {
  position: absolute;
	height: 50px;
	z-index: 1000 !important;
	width: 100%;
	background: rgba(0, 0, 0, 0.60);
  bottom: 0px;
}

.menu .inner {
	padding:0;
	font-weight: 400;
	margin: 0 auto;
  max-width: 1024px;
}

.navBtn {
	display: block;
	height: 35px;
	width: 12.65%;
	float: left;
	margin: 0px 0px 0 0;
	text-decoration: none;
	font-size: 14px;
	padding: 15px 2% 0 2%;
	text-align: center;

	color: #fff;
}

.menu a:hover {
	background: rgba(0, 0, 0, 0.80);
  color: white;
}

.active {
	background: rgba(0, 0, 0, 0.80);
}

section, .about, .education, .experiences, .skills, .contact {
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
  z-index: 10;
}

.about {
  margin-top: 100vh;
}

.aboutContent {
  margin-top: 40px;
  text-align: justify;
}

.circular {
	border-radius: 150px;
	-webkit-border-radius: 150px;
	-moz-border-radius: 150px;
	box-shadow: 0 0 8px rgba(0, 0, 0, .8);
	-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .8);
	-moz-box-shadow: 0 0 8px rgba(0, 0, 0, .8);
}

#education {
  width: 100%;
  background: url(../images/background-education.jpg) no-repeat fixed center;
  background-size: cover;
  box-sizing: border-box;
}

#experiences {
  color: rgb(50,50,50);
  font-family: 'Open Sans', sans-serif;
  font-size: 112.5%;
  line-height: 1.6em;
}


#skills {
  width: 100%;
  background: url(../images/background-skills.jpg) no-repeat fixed center;
  background-size: cover;
}

#skills .next {
  margin-top: 20px;
}

#contact {
  text-align: center;
}

#contact a {
  color: black;
  text-align: center;
}

#contact a:hover {
  color: rgb(133, 133, 133);
}

#contact i {
  margin-top: 20px;
  font-size: 300%;
}

footer, #footer {
  margin-top: 50px;
  text-align: center;
  color: #cacaca;
}

#footer i {
  color: #f4a9a9;
}

#footer a {
  color: #b2b2b2;
  font-size: 80%;
}

/* Responsive */
@media (max-width: 920px) {
    #wrapper {
      background-color: white;
    }

    header, #header {
      display: none;
    }

    #about {
      margin-top: 0;
    }

    #mobileheader {
      display: block;
    }

    .skill-card {
      margin-bottom: 10px;
    }
}
